//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NewsDetails',
    data() {
        return {
            NewsDetailsList: {
                PublishDate: "",
                TitleAr: "",
                TitleEn:"",
                ContentAr: "",
                ContentEn:"",
                NewsCategory: {
                    NameAr: "",
                    NameEn:""
                },
                PhotoAttachmentAr: {
                    
                },
                PhotoAttachmentEn: {

                }
            },
            NewsByCategoryIdList: [],
        }
    },
    methods: {
formatDate(value) {
 if(localStorage.lang  == 'ar'){
let date = new Date(value);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
//   return  days[date.getDay()] + " - " + day + " - " + month + " - " + year;
   return   day + " - " + month + " - " + year;
 }
    
else if(localStorage.lang  == 'en'){
let date = new Date(value);
  const day = date.toLocaleString("default", { day: "2-digit" });
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.toLocaleString("default", { year: "numeric" });
    return    day + " - " + month + " - " + year;
 }

},
        loadData() {
            let self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + 'News/GetNewsDetailsById/' + self.$route.params?.id)
                .then(response => {
                    debugger;
                    if (response.data.Data) {
                        self.NewsDetailsList = response.data.Data;
                        if (self.NewsDetailsList.NewsCategoryId != 0 && self.NewsDetailsList.NewsCategoryId != undefined) {
                            self.axios
                                .get(process.env.VUE_APP_APIEndPoint + 'News/GetNewsByCategoryId/' + self.NewsDetailsList.NewsCategoryId)
                                .then(response => {
                                    self.NewsByCategoryIdList = response.data.Data;
                                });
                        }
                    }
                    
                });
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route() {
            this.loadData();
        }
        //"$route.params?.id":function() {
        //    this.loadData();

        //}
    },
    updated() {
        homeNewsSliderInit();
    }
}
